import React from 'react';
import '../theme.js';
import { Tag, Row, Col, Collapse, Descriptions } from 'antd';
import Mollie from '../service/mollie';
import Logo from '../components/logo.js';
import Seo from '../components/seo';
import Invoice from '../components/invoice';

const { Panel } = Collapse;

class Preview extends React.Component {
    state = {
        order: null
    };

    interval = null;

    componentDidMount() {
        this.handleWindowSizeChange();
        window.addEventListener('resize', this.handleWindowSizeChange);

        if (window.location.hash) {
            localStorage.setItem('mollie_checkoutid', window.location.hash.substring(1));
        }

        let x = function () {
            if (localStorage.getItem('mollie_checkoutid') !== null) {
                let mollie = new Mollie();
                mollie.getFactoryOrder().then(
                    function (data) {
                        this.setState({ order: data });
                    }.bind(this)
                );
            }
        }.bind(this);
        x();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    handleWindowSizeChange = () => {
        const isMobile = window.innerWidth <= 992;
        if (isMobile !== this.state.isMobile) this.setState({ isMobile: isMobile });
    };

    renderAddress(title, address) {
        return (
            <Descriptions title={title} column={1}>
                <Descriptions.Item label="Company">{address.organizationName}</Descriptions.Item>
                <Descriptions.Item label="Street">{address.streetAndNumber}</Descriptions.Item>
                <Descriptions.Item label="City">
                    {address.postalCode}, {address.city}
                </Descriptions.Item>
                <Descriptions.Item label="Country">{address.country}</Descriptions.Item>
            </Descriptions>
        );
    }

    renderOrder(order) {
        if (order) {
            return (
                <>
                    <div style={{ textAlign: 'left', maxWidth: '960px', margin: 'auto', padding: '20px' }}>
                        <Row style={{ marginBottom: '1em' }}>
                            <Col md={{ span: 12 }}>{this.renderAddress('Shipping Address', order.shippingAddress)}</Col>
                            <Col md={{ span: 12 }}>
                                <Descriptions title="Contact" column={1}>
                                    <Descriptions.Item label="Company">{order.metadata.company}</Descriptions.Item>
                                    <Descriptions.Item label="Given Name">{order.metadata.givenName}</Descriptions.Item>
                                    <Descriptions.Item label="Family Name">{order.metadata.familyName}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{order.metadata.email}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>

                        <Collapse
                            defaultActiveKey={order.lines.map(function (item, i) {
                                return i;
                            })}
                        >
                            {order.lines.map(function (item, i) {
                                if (Object.keys(item.metadata).length === 0) {
                                    return null;
                                }
                                return (
                                    <Panel header={item.quantity + ' x Table #' + i} key={i}>
                                        <Descriptions title="Table">
                                            <Descriptions.Item label="Length">{item.metadata.length}</Descriptions.Item>
                                            <Descriptions.Item label="Width">{item.metadata.width}</Descriptions.Item>
                                            <Descriptions.Item label="Height">{item.metadata.height}</Descriptions.Item>
                                            <Descriptions.Item label="Gates">{item.metadata.gates}</Descriptions.Item>
                                            <Descriptions.Item label="Plugs">{item.metadata.plugs}</Descriptions.Item>
                                            <Descriptions.Item label="Color">
                                                {item.metadata.color}
                                                &nbsp;&nbsp;
                                                <Tag color={item.metadata.color}>color</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Material">{item.metadata.material}</Descriptions.Item>
                                        </Descriptions>
                                    </Panel>
                                );
                            })}
                        </Collapse>

                        <Collapse style={{ margin: '100px 0' }}>
                            <Panel header="Invoice">
                                <Invoice order={order} />
                            </Panel>
                            <Panel header="Mollie">
                                <pre>{JSON.stringify(order, undefined, 2)}</pre>
                            </Panel>
                        </Collapse>
                    </div>
                </>
            );
        }
        return <div style={{textAlign: 'center'}}>Load Order By adding the mollie order id. Example "/factory/#ord_xxxxxx"</div>;
    }

    render() {
        const { order } = this.state;

        return (
            <>
                <Seo />
                <div className="plato-logo">
                    <Logo />
                </div>

                {this.renderOrder(order)}
            </>
        );
    }
}

export default Preview;
