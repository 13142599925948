import React from 'react';
import { Table, Row, Col, Descriptions } from 'antd';
import { CountryRegionData } from './countries';

class Invoice extends React.Component {
    renderAddress(title, address, vatnumber) {
        return (
            <Descriptions title={title} column={1}>
                <Descriptions.Item label="Company">{address.organizationName}</Descriptions.Item>
                <Descriptions.Item label="Street">{address.streetAndNumber}</Descriptions.Item>
                <Descriptions.Item label="City">
                    {address.postalCode}, {address.city}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                    {CountryRegionData.map(function (item, i) {
                        if (item.shortCode === address.country) {
                            return item.name;
                        }
                        return null;
                    })}
                </Descriptions.Item>

                {vatnumber && <Descriptions.Item label="VAT Number">{vatnumber}</Descriptions.Item>}
            </Descriptions>
        );
    }

    renderTable(lines) {
        let total_ht = 0;
        let vatamount = 0;
        let total_ttc = 0;
        let vat_rate = 0;

        lines.map(function (item) {
            vat_rate = item.vatRate;
            total_ht += parseFloat(item.totalAmount.value) / (1.0 + parseFloat(item.vatRate) / 100);
            vatamount += parseFloat(item.totalAmount.value) - parseFloat(item.totalAmount.value) / (1.0 + parseFloat(item.vatRate) / 100);
            total_ttc += parseFloat(item.totalAmount.value);
            return null;
        });
        const columns = [
            {
                title: 'Description',
                dataIndex: 'name',
                key: 'description'
            },
            {
                title: 'Unit Price',
                key: 'unitprice',
                align: 'right',
                render: (text, record) => (
                    <span>
                        {(record.unitPrice.value / (1.0 + parseFloat(record.vatRate) / 100)).toFixed(2)} {record.unitPrice.currency}
                    </span>
                )
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                align: 'right'
            },
            {
                title: 'Total',
                key: 'total',
                align: 'right',
                render: (text, record) => (
                    <span>
                        {(record.totalAmount.value / (1.0 + parseFloat(record.vatRate) / 100)).toFixed(2)} {record.totalAmount.currency}
                    </span>
                )
            }
        ];

        const columns_sum = [
            {
                key: '1',
                title: 'Title',
                render: (text, record) => {
                    return <strong>{record.title}</strong>;
                }
            },
            {
                key: '2',
                title: 'Value',
                dataIndex: 'value',
                align: 'right'
            }
        ];

        let sum = [
            {
                title: 'Taxable Subtotal',
                value: total_ht.toFixed(2) + ' EUR'
            },
            {
                title: 'VAT (' + vat_rate + '%)',
                value: vatamount.toFixed(2) + ' EUR'
            },
            {
                title: 'TOTAL',
                value: total_ttc.toFixed(2) + ' EUR'
            }
        ];

        return (
            <div>
                <Table columns={columns} dataSource={lines} pagination={false} rowKey="id" />
                <Row>
                    <Col span={12} offset={12}>
                        <Table columns={columns_sum} dataSource={sum} pagination={false} showHeader={false} />
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { order } = this.props;

        return (
            <div>
                <Row style={{ marginBottom: '1em' }}>
                    <Col md={{ span: 12 }}>{this.renderAddress('Billing Address', order.billingAddress, order.metadata.vatnumber)}</Col>
                    <Col md={{ span: 12 }}>{this.renderAddress('Shipping Address', order.shippingAddress)}</Col>
                </Row>

                {this.renderTable(order.lines)}
            </div>
        );
    }
}

export default Invoice;
